import user from './user'
import loader from './loader'
import search from './search'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  user,
  loader,
  search,
})

export default rootReducer
