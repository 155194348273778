import './src/styles/style.css'
import 'antd/dist/antd.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import wrapWithProvider from './with-wrap-provider'
import { requestInterceptor, responseInterceptor } from './src/intercepter'

requestInterceptor()
responseInterceptor()

export const wrapRootElement = wrapWithProvider

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.pathname === '/medical-sales-careers/') {
    return false
  } else {
    return true
  }
}

export const onClientEntry = () => {
  const _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', window.location.pathname])
}

export const onRouteUpdate = ({ location }) => {
  var _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', `${location?.pathname || ''}${location.search || ''}`])
  _hsq.push(['trackPageView'])
}
