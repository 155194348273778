import cookie from 'react-cookies'

let windowCheck = typeof window !== 'undefined'
const initialState = {
  products:
    windowCheck && localStorage.getItem('products')
      ? JSON.parse(localStorage.getItem('products'))
      : [],
  profileData:
    windowCheck && localStorage.getItem('userProfileData')
      ? JSON.parse(localStorage.getItem('userProfileData'))
      : null,
  namedUserEmployerProfiles:
    windowCheck && localStorage.getItem('namedUserEmployerProfiles')
      ? JSON.parse(localStorage.getItem('namedUserEmployerProfiles'))
      : null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRODUCTS':
      windowCheck &&
        localStorage.setItem('products', JSON.stringify(action.payload))
      return {
        ...state,
        products: action.payload,
      }
    case 'LOGOUT':
      cookie.remove('user', { path: '/' })
      cookie.remove('userRole', { path: '/' })
      cookie.remove('selectedNamedUserEmployerId', { path: '/' })
      if (windowCheck) {
        localStorage.removeItem('products')
        localStorage.removeItem('userProfileData')
        localStorage.removeItem('namedUserEmployerProfiles')
        if (action.payload?.utm_source === 'email') {
          window.location.href = '/?utm_source=email'
        } else {
          window.location.href = '/'
        }
      }
      return initialState
    case 'SET_PROFILE_DATA':
      windowCheck &&
        localStorage.setItem('userProfileData', JSON.stringify(action.payload))
      return {
        ...state,
        profileData: action.payload,
      }
    case 'SET_NAMED_USER_EMPLOYER_PROFILES':
      windowCheck &&
        localStorage.setItem(
          'namedUserEmployerProfiles',
          JSON.stringify(action.payload)
        )
      return {
        ...state,
        namedUserEmployerProfiles: action.payload,
      }
    default:
      return state
  }
}

export default userReducer
