import axios from 'axios'
import cookie from 'react-cookies'
import { notification } from 'antd'
import { navigate } from 'gatsby-link'
export const requestInterceptor = () => {
  axios.interceptors.request.use(
    function (config) {
      if (cookie.load('user')) {
        const token = cookie.load('user').access_token
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}
export const responseInterceptor = () => {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response) {
        if (error.response.status === 403) {
          notification.info({
            message: 'Alert',
            description: 'You are not authorized for this action. Thank you.',
            duration: 10,
          })
        }
      }
      return Promise.reject(error)
    }
  )
}
